<template>
	<section id="payment-methods-panel" class="q-pl-md q-pr-md q-pb-md text-center">
		<q-img
			id="mastercard-logo"
			class="payment-method-image"
			src="@/assets/images/mastercard-logo.svg"
			no-spinner
			alt="Mastercard image"
		/>
		<q-img
			id="visa-logo"
			class="payment-method-image"
			src="@/assets/images/visa-logo-light.svg"
			no-spinner
			alt="Visa image"
			height="18px"
		/>
		<q-img
			id="prostir-logo"
			class="payment-method-image"
			src="@/assets/images/prostir-logo-light.svg"
			no-spinner
			alt="Prostir image"
			height="20px"
		/>
		<q-img
			id="pci-dss-logo"
			class="payment-method-image"
			src="@/assets/images/pci-dss-compliant-logo.svg"
			no-spinner
			alt="PCI DSS Compliant image"
		/>
		<q-img
			id="apple-pay-logo"
			class="payment-method-image"
			src="@/assets/images/apple-pay.svg"
			no-spinner
			alt="Apple Pay logo"
		/>
		<q-img
			id="google-pay-logo"
			class="payment-method-image"
			src="@/assets/images/google-pay.svg"
			no-spinner
			alt="Google Pay logo"
		/>
		<q-img
			id="bvr-pay-logo"
			class="payment-method-image"
			src="@/assets/images/bvr-pay.svg"
			no-spinner
			alt="Bvr Pay logo"
		/>
	</section>
</template>

<style scoped lang="scss">
#mastercard-logo {
	max-width: 35px;
}

#visa-logo,
#pci-dss-logo,
#google-pay-logo {
	max-width: 55px;
}

#prostir-logo {
	min-width: 104px;
}

#apple-pay-logo {
	max-width: 38px;
}

#bvr-pay-logo {
	max-width: 50px;
}

@media screen and (max-width: 600px) {
	.payment-method-image {
		margin-right: 15px;
		max-width: 50px;
		margin-top: 15px;
	}

	#payment-methods-panel {
		width: 100%;
	}
}

@media screen and (min-width: 600px) {
	.payment-method-image {
		margin-right: 20px;
		margin-top: 15px;
		max-width: 70px;
	}

	#payment-methods-panel {
		width: 650px;
	}
}

@media screen and (min-width: 1675px) {
	.payment-method-image {
		max-width: 80px;
		margin-right: 20px;
	}

	#payment-methods-panel {
		width: 850px;
		margin-left: 50px;
	}
}
</style>
