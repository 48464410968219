import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "page-container",
  class: "column",
  ref: "pageContainer"
}
const _hoisted_2 = {
  id: "content",
  class: "flex items-center justify-center"
}
const _hoisted_3 = {
  id: "router-view-section",
  class: "items-center justify-center"
}

import { type ComputedRef, computed } from "vue";
import PageFooter from "@/components/PageFooter/PageFooter.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import LandscapeOrientationWarning from "@/components/LandscapeOrientationWarning/LandscapeOrientationWarning.vue";
import WaitSpinner from "@/common-components/WaitSpinner.vue";
import { useP2PCommonStore } from "@/store/P2PCommonStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const p2PCommonStore = useP2PCommonStore();

const isLoading: ComputedRef<boolean> = computed(() => p2PCommonStore.getIsAppWaiting);
const loadingMessage: ComputedRef<string> = computed(() => p2PCommonStore.getLoadingMessage);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    id: "main-layout",
    class: "main-background",
    ref: "mainLayout",
    view: "lHh Lpr lFf"
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(PageHeader, {
          id: "header",
          operationName: "Поповнення за посиланням"
        }),
        _createElementVNode("article", _hoisted_2, [
          _createVNode(LandscapeOrientationWarning, {
            id: "orientation-warning",
            class: "absolute-center"
          }),
          _withDirectives(_createVNode(WaitSpinner, {
            id: "wait-spinner",
            class: "absolute-center",
            message: loadingMessage.value
          }, null, 8, ["message"]), [
            [_vShow, isLoading.value]
          ]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_router_view, { id: "router-view" }, null, 512), [
              [_vShow, !isLoading.value]
            ])
          ])
        ]),
        _createVNode(PageFooter, { id: "footer" })
      ], 512)
    ]),
    _: 1
  }, 512))
}
}

})