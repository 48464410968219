import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "section-header" }
const _hoisted_2 = { id: "section-value" }


export default /*@__PURE__*/_defineComponent({
  __name: 'SupportBlock',
  props: {
    headerCaption: {},
    headerValue: {}
  },
  emits: ["expand"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const handleExpand = (): void => {
	emit("expand");
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("section", {
    id: "support-section",
    ref: "supportSection",
    onClick: handleExpand
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.headerCaption), 1),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.headerValue), 1),
    _createVNode(_component_q_icon, {
      id: "open-popup-icon",
      class: "expand-icon q-ml-sm",
      name: "expand_more"
    })
  ], 512))
}
}

})