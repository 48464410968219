<template>
	<div class="social-icon-section text-center">
		<a href="https://t.me/BVRbot" target="_blank" rel="noreferrer noopener">
			<q-img class="social-icon" src="@/assets/images/telegram.svg" width="60px" height="60px" alt="Telegram" />
		</a>
		<br />
		<span class="social-network-name">Telegram</span>
	</div>
</template>

<style lang="scss" scoped>
.social-icon-section {
	font-size: to-rem(13);
}

.social-icon {
	height: 50px;
}

.social-network-name {
	display: inline-block;
	margin-top: 4px;
	font-size: to-rem(14);
}
</style>
