import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = {
  id: "header",
  class: "flex items-center full-width q-pl-md q-pr-md"
}
const _hoisted_2 = {
  id: "brand-section",
  class: "flex"
}
const _hoisted_3 = { id: "operation-name-container" }
const _hoisted_4 = { id: "operation-name-caption" }
const _hoisted_5 = { id: "support-panel-container" }

import HeaderTechnicalSupportPanel from "@/components/HeaderTechnicalSupportPanel/HeaderTechnicalSupportPanel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  props: {
    operationName: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("article", { id: "logo-holder" }, [
        _createElementVNode("img", {
          id: "logo",
          src: _imports_0
        })
      ], -1)),
      _cache[1] || (_cache[1] = _createElementVNode("article", { id: "brand-name-container" }, [
        _createElementVNode("div", null, "Банк"),
        _createElementVNode("div", { class: "logo-second-line" }, "Власний Рахунок")
      ], -1)),
      _createElementVNode("article", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.operationName), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(HeaderTechnicalSupportPanel)
    ])
  ]))
}
}

})