<template>
	<q-dialog v-model="popupOpen">
		<q-card id="card" class="q-pa-md full-width">
			<q-card-section class="row justify-between">
				<span id="card-header">Є запитаннячка?</span>
				<q-btn class="close-btn relative-position" icon="close" color="black" flat round dense v-close-popup />
			</q-card-section>

			<q-card-section id="dialog-main-section">
				<contact-view contact="080 030 26 00" contact-description="Безкоштовно по Україні" />
				<contact-view class="q-mt-md" contact="044 492 26 00" contact-description="Для міжнародних дзвінків" />
			</q-card-section>
			<q-card-actions class="q-pt-sm q-pl-md q-pr-md q-pb-md">
				<section
					id="social-network-buttons-container"
					class="row justify-center items-center justify-center full-width"
				>
					<viber-button class="q-mr-xl" />
					<telegram-button />
				</section>
			</q-card-actions>
		</q-card>
	</q-dialog>
</template>

<script lang="ts" setup>
import { type Ref, ref } from "vue";
import TelegramButton from "@/common-components/TelegramButton.vue";
import ViberButton from "@/common-components/ViberButton.vue";
import ContactView from "./child-components/ContactView.vue";

const popupOpen: Ref<boolean> = ref(false);

const setPopupState = (isOpen: boolean): void => {
	popupOpen.value = isOpen;
};

defineExpose({
	setPopupState,
});
</script>

<style lang="scss" scoped>
#social-network-buttons-container {
	height: 120px;
	background-color: #f8f9f9;
	border-radius: 25px;
	padding-top: 6px;
}

#card-header {
	font-size: to-rem(20);
	font-family: Gilroy-bold;
}

#card {
	border-radius: 25px;
	max-width: 335px;
}

.close-btn {
	top: -2px;
	left: 8px;
}

@media screen and (max-height: 500px) {
	#dialog-main-section {
		margin-top: -40px;
	}
}

@media screen and (min-height: 600px) {
	#dialog-main-section {
		margin-top: -20px;
	}
}

@media screen and (min-height: 800px) {
	#dialog-main-section {
		margin-top: -20px;
	}
}
</style>
