<template>
	<q-layout id="main-layout" class="main-background" ref="mainLayout" view="lHh Lpr lFf">
		<section id="page-container" class="column" ref="pageContainer">
			<page-header id="header" operationName="Поповнення за посиланням" />
			<article id="content" class="flex items-center justify-center">
				<landscape-orientation-warning id="orientation-warning" class="absolute-center" />
				<wait-spinner id="wait-spinner" class="absolute-center" :message="loadingMessage" v-show="isLoading" />
				<div id="router-view-section" class="items-center justify-center">
					<router-view id="router-view" v-show="!isLoading"></router-view>
				</div>
			</article>
			<page-footer id="footer" />
		</section>
	</q-layout>
</template>

<script lang="ts" setup>
import { type ComputedRef, computed } from "vue";
import PageFooter from "@/components/PageFooter/PageFooter.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import LandscapeOrientationWarning from "@/components/LandscapeOrientationWarning/LandscapeOrientationWarning.vue";
import WaitSpinner from "@/common-components/WaitSpinner.vue";
import { useP2PCommonStore } from "@/store/P2PCommonStore";

const p2PCommonStore = useP2PCommonStore();

const isLoading: ComputedRef<boolean> = computed(() => p2PCommonStore.getIsAppWaiting);
const loadingMessage: ComputedRef<string> = computed(() => p2PCommonStore.getLoadingMessage);
</script>

<style lang="scss" scoped>
#main-layout {
	min-width: 335px;
}

#page-container {
	min-height: 100dvh;
}

@supports not (min-height: 100dvh) {
	#page-container {
		min-height: 100vh;
	}
}

#router-view-section {
	display: flex;
}

#content {
	flex: 1;
}

#footer {
	height: $pageFooterHeight;
}

#orientation-warning {
	display: none;
}

@media (max-height: 500px) {
	#header {
		height: Calc(#{$pageHeaderHeight} - 40px);
	}
}

@media (min-height: 501px) {
	#header {
		height: $pageHeaderHeight - 30px;
	}
}

@media (min-height: 700px) {
	#header {
		height: $pageHeaderHeight;
	}
}

@media (max-height: 500px) and (orientation: landscape) and (hover: none) {
	#orientation-warning {
		display: block;
	}
	#header {
		display: none;
	}

	#footer {
		display: none;
	}

	#wait-spinner {
		display: none;
	}

	#router-view-section {
		display: none;
	}
}
</style>
