<template>
	<support-block
		id="support-block"
		ref="supportBlock"
		header-caption="Безкоштовно по Україні"
		header-value="080 030 26 00"
		@expand="handlePopupOpen"
	/>
	<support-contacts-popup ref="supportPopupDialog" />
</template>

<script lang="ts" setup>
import { type Ref, ref } from "vue";
import SupportBlock from "./child-components/SupportBlock.vue";
import SupportContactsPopup from "@/components/SupportContactsPopup/SupportContactsPopup.vue";

const supportPopupDialog: Ref<InstanceType<typeof SupportContactsPopup> | null> = ref(null);

const handlePopupOpen = (): void => {
	supportPopupDialog.value?.setPopupState(true);
};
</script>

<style scoped lang="scss">
#support-block {
	cursor: pointer;
	color: whitesmoke;
}

@media screen and (max-width: $minimumDesktopWidth) {
	#support-block {
		display: block;
	}
}

@media screen and (min-width: $minimumDesktopWidth) {
	#support-block {
		display: none;
	}
}
</style>
