<template>
	<section>
		<article id="message-container" class="column items-center">
			<q-img id="rotation-image" src="@/assets/images/smartphone.svg" />
			<p id="rotate-device-message-text" class="q-mt-md text-center full-width">
				Розверни смартфон вертикально, щоб тобі було зручніше
			</p>
		</article>
	</section>
</template>

<style lang="scss" scoped>
@keyframes deviceRotationAnimation {
	0% {
		opacity: 0;
		-webkit-transform: rotate(90deg);
	}
	20% {
		opacity: 1;
		-webkit-transform: rotate(90deg);
	}
	60% {
		opacity: 1;
		-webkit-transform: rotate(0deg);
	}
	100% {
		opacity: 0;
		-webkit-transform: rotate(0deg);
	}
}

#rotation-image {
	animation-name: deviceRotationAnimation;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	max-height: 70px;
	max-width: 70px;
}

#rotate-device-message-text {
	color: whitesmoke;
	font-size: to-rem(20);
	font-family: Gilroy-medium;
}
</style>
