import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column items-center" }
const _hoisted_2 = {
  id: "spinner-caption",
  class: "flex text-center text-bold"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'WaitSpinner',
  props: {
    message: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_q_spinner_gears, {
      class: "flex",
      size: "8em",
      color: "white",
      ref: "spinnerGears"
    }, null, 512),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1)
  ]))
}
}

})