<template>
	<section class="footer-container row items-center justify-center relative-position full-width">
		<div id="support-panel-container">
			<technical-support-contacts-panel class="q-mr-xl" ref="technicalSupportContactsPanel" />
		</div>
		<payment-methods-panel ref="paymentMethodsPanel" />
	</section>
</template>

<script lang="ts" setup>
import { type Ref, ref } from "vue";
import PaymentMethodsPanel from "./child-components/PaymentMethodsPanel.vue";
import TechnicalSupportContactsPanel from "./child-components/TechnicalSupportContactsPanel.vue";

const technicalSupportContactsPanel: Ref<InstanceType<typeof TechnicalSupportContactsPanel> | null> = ref(null);
const paymentMethodsPanel: Ref<InstanceType<typeof PaymentMethodsPanel> | null> = ref(null);
</script>

<style scoped lang="scss">
@media screen and (max-width: $minimumDesktopWidth) {
	#support-panel-container {
		display: none;
	}
}

@media screen and (min-width: $minimumDesktopWidth) {
	#support-panel-container {
		display: flex;
	}
}

.footer-container {
	border-top: 1px solid $layout-border-color;
}
</style>
