import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { type Ref, ref } from "vue";
import SupportBlock from "./child-components/SupportBlock.vue";
import SupportContactsPopup from "@/components/SupportContactsPopup/SupportContactsPopup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderTechnicalSupportPanel',
  setup(__props) {

const supportPopupDialog: Ref<InstanceType<typeof SupportContactsPopup> | null> = ref(null);

const handlePopupOpen = (): void => {
	supportPopupDialog.value?.setPopupState(true);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(SupportBlock, {
      id: "support-block",
      ref: "supportBlock",
      "header-caption": "Безкоштовно по Україні",
      "header-value": "080 030 26 00",
      onExpand: handlePopupOpen
    }, null, 512),
    _createVNode(SupportContactsPopup, {
      ref_key: "supportPopupDialog",
      ref: supportPopupDialog
    }, null, 512)
  ], 64))
}
}

})