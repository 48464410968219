import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/:id",
		name: "main",
		component: () => import(/* webpackChunkName: "main" */ "@/views/MainView.vue"),
	},
	{
		path: "/internal-error",
		name: "error-page",
		component: () => import(/* webpackChunkName: "error" */ "@/views/InternalError.vue"),
	},
	{
		path: "/:catchAll(.*)",
		name: "not-found-page",
		component: () => import(/* webpackChunkName: "not-found" */ "@/views/NotFoundPage.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
