import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "social-network-buttons-container",
  class: "row justify-center items-center justify-center full-width"
}

import { type Ref, ref } from "vue";
import TelegramButton from "@/common-components/TelegramButton.vue";
import ViberButton from "@/common-components/ViberButton.vue";
import ContactView from "./child-components/ContactView.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SupportContactsPopup',
  setup(__props, { expose: __expose }) {

const popupOpen: Ref<boolean> = ref(false);

const setPopupState = (isOpen: boolean): void => {
	popupOpen.value = isOpen;
};

__expose({
	setPopupState,
});

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: popupOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((popupOpen).value = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        id: "card",
        class: "q-pa-md full-width"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between" }, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("span", { id: "card-header" }, "Є запитаннячка?", -1)),
              _withDirectives(_createVNode(_component_q_btn, {
                class: "close-btn relative-position",
                icon: "close",
                color: "black",
                flat: "",
                round: "",
                dense: ""
              }, null, 512), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { id: "dialog-main-section" }, {
            default: _withCtx(() => [
              _createVNode(ContactView, {
                contact: "080 030 26 00",
                "contact-description": "Безкоштовно по Україні"
              }),
              _createVNode(ContactView, {
                class: "q-mt-md",
                contact: "044 492 26 00",
                "contact-description": "Для міжнародних дзвінків"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { class: "q-pt-sm q-pl-md q-pr-md q-pb-md" }, {
            default: _withCtx(() => [
              _createElementVNode("section", _hoisted_1, [
                _createVNode(ViberButton, { class: "q-mr-xl" }),
                _createVNode(TelegramButton)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})