<template>
	<section id="support-section" ref="supportSection" @click="handleExpand">
		<span id="section-header">{{ headerCaption }}</span>
		<br />
		<span id="section-value">{{ headerValue }}</span>
		<q-icon id="open-popup-icon" class="expand-icon q-ml-sm" name="expand_more" />
	</section>
</template>

<script lang="ts" setup>
defineProps<{
	headerCaption: string;
	headerValue: string;
}>();

const emit = defineEmits<{
	expand: [];
}>();

const handleExpand = (): void => {
	emit("expand");
};
</script>

<style lang="scss" scoped>
#section-header {
	font-family: Gilroy-light;
	color: $core-light-gray-1;
}

#section-value {
	font-family: Gilroy-medium;
	font-size: to-rem(16);
}

.expand-icon {
	color: $core-light-gray-1;
	font-size: to-rem(18);
}

@media screen and (max-height: 500px) {
	#section-header {
		font-size: to-rem(12);
	}

	#dialog-main-section {
		margin-top: -40px;
	}

	#section-value {
		font-size: to-rem(13);
	}
}

@media screen and (min-height: 600px) {
	#section-header {
		font-size: to-rem(12);
	}

	#section-value {
		font-size: to-rem(13);
	}

	#dialog-main-section {
		margin-top: -20px;
	}
}

@media screen and (min-height: 800px) {
	#section-header {
		font-size: to-rem(15);
	}

	#dialog-main-section {
		margin-top: -20px;
	}

	#section-value {
		font-size: to-rem(16);
	}
}

@media screen and (max-width: 395px) {
	#section-header {
		font-size: to-rem(12);
	}

	#section-value {
		font-size: to-rem(13);
	}
}
@media screen and (min-width: 900px) {
	#section-header {
		font-size: to-rem(15);
	}

	#section-value {
		font-size: to-rem(16);
	}
}
</style>
