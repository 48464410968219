import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-container row items-center justify-center relative-position full-width" }
const _hoisted_2 = { id: "support-panel-container" }

import { type Ref, ref } from "vue";
import PaymentMethodsPanel from "./child-components/PaymentMethodsPanel.vue";
import TechnicalSupportContactsPanel from "./child-components/TechnicalSupportContactsPanel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageFooter',
  setup(__props) {

const technicalSupportContactsPanel: Ref<InstanceType<typeof TechnicalSupportContactsPanel> | null> = ref(null);
const paymentMethodsPanel: Ref<InstanceType<typeof PaymentMethodsPanel> | null> = ref(null);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TechnicalSupportContactsPanel, {
        class: "q-mr-xl",
        ref_key: "technicalSupportContactsPanel",
        ref: technicalSupportContactsPanel
      }, null, 512)
    ]),
    _createVNode(PaymentMethodsPanel, {
      ref_key: "paymentMethodsPanel",
      ref: paymentMethodsPanel
    }, null, 512)
  ]))
}
}

})