<template>
	<section>
		<a id="viber-link" href="https://bvr.ua/_viber" target="_blank" rel="noreferrer noopener">
			<q-img
				class="viber-logo q-ml-md"
				ref="viber-button-img"
				src="@/assets/images/viber.svg"
				height="50px"
				width="50px"
			/>
		</a>
		<a id="telegram-link" href="https://t.me/BVRbot" target="_blank" rel="noreferrer noopener">
			<q-img
				class="telegram-logo q-ml-md"
				ref="telegram-button-img"
				src="@/assets/images/telegram.svg"
				height="50px"
				width="50px"
			/>
		</a>
	</section>
	<section>
		<div id="support-phone" class="contact">044 492 26 00</div>
		<div id="support-phone-description" class="contact-description">За тарифами вашого оператора</div>
	</section>
	<section>
		<div id="support-mobile-phone" class="contact">0 800 302 600</div>
		<div id="support-mobile-phone-description" class="contact-description">Безкоштовно по Україні</div>
	</section>
	<section>
		<div id="support-email" class="contact">bank@bvr.com.ua</div>
		<div id="support-email-description" class="contact-description">Пиши нам на пошту</div>
	</section>
</template>

<style scoped lang="scss">
section {
	margin-left: 10px;
	color: whitesmoke;
}

.contact {
	margin-left: 30px;
	font-size: to-rem(19);
	font-family: Gilroy-light;
}

.contact-description {
	margin-left: 30px;
	font-size: to-rem(13);
	font-family: Gilroy-light;
}
</style>
