import { ErrorCode } from "@/enums/ErrorCode";
import { UserScreen } from "@/enums/UserScreen";
import P2PCheckoutOptions from "@/models/Options/P2PCheckoutOptions";
import BaseResponse from "@/models/Responses/BaseResponse";
import router from "@/router";
import { HttpRequestDispatcher, HttpRequestMethod } from "@/utilities/HttpRequestDispatcher";
import * as SignalR from "@microsoft/signalr";
import { defineStore } from "pinia";
import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';;

export const useP2PCommonStore = defineStore("P2PCommonStore", {
	state: () => ({
		isLoading: false,
		loadingMessage: "",
		lastUsingShortLink: null as string | null,
		errorCode: null as ErrorCode | null,
		currentScreen: UserScreen.P2PTransactionMain,
		checkoutOptions: null as P2PCheckoutOptions | null,
		isApplePayReady: false,
		isGooglePayReady: false,
		transactionEventId: null as string | null,
	}),
	getters: {
		getIsApplePayReady: state => state.isApplePayReady,
		getIsGooglePayReady: state => state.isGooglePayReady,
		getIsAppWaiting: state => state.isLoading,
		getLoadingMessage: state => state.loadingMessage,
		getCurrentUserScreen: state => state.currentScreen,
		getErrorCode: state => state.errorCode,
		getTransactionEventId: state => {
			return LocalStorage.getItem("transactionEventId") || state.transactionEventId;
		},
		getCheckoutOptions: state => state.checkoutOptions,
		getLastUsingShortLink: state => LocalStorage.getItem("lastUsedShortLink"),
	},
	actions: {
		goToLink(url: string) {
			window.open(url, "_self");
		},
		clearTransactionEventId() {
			LocalStorage.remove("transactionEventId");
		},
		async setCheckoutOptions(options: P2PCheckoutOptions) {
			this.$patch({ checkoutOptions: options });
		},
		setLastUsingShortLink(shortLink: string) {
			LocalStorage.set("lastUsedShortLink", shortLink);
		},
		setTransactionEventId(transactionEventId: string) {
			if (transactionEventId) {
				this.$patch({ transactionEventId });
				LocalStorage.set("transactionEventId", transactionEventId);
			} else {
				console.error("P2PCommonStore: transactionEventId cannot be null or empty");
			}
		},
		setupIsLoadingFlag(isAppWaiting: boolean, message: string = ""): void {
			this.$patch({ loadingMessage: message });
			this.$patch({ isLoading: isAppWaiting });
		},
		setCurrentUserScreen(currentScreen: UserScreen): void {
			this.$patch({ currentScreen: currentScreen });
		},
		setErrorCode(errorCode: ErrorCode | null): void {
			this.$patch({ errorCode: errorCode });
		},
		setIsApplePayReady(isReady: boolean): void {
			this.$patch({ isApplePayReady: isReady });
		},
		setIsGooglePayReady(isReady: boolean): void {
			this.$patch({ isGooglePayReady: isReady });
		},
		loadCheckoutOptions(): Promise<void> {
			const requestUrl = "/api/P2POptions";

			return HttpRequestDispatcher.SendRequest(HttpRequestMethod.GET, requestUrl).then((response: any) => {
				if (!response || !response.data) {
					console.error("Can't load checkout options");
					return;
				}
				const options: P2PCheckoutOptions = response.data;
				this.setCheckoutOptions(options);
			});
		},
		async send3dsConfirm(transactionEventId: string) {
			this.setupIsLoadingFlag(true, "Підтвердження оплати...");

			try {
				await HttpRequestDispatcher.SendRequest(
					HttpRequestMethod.GET,
					`/api/P2PConfirmation/Confirm3dsSecurityPayment/${transactionEventId}`
				).then((response: any) => {
					const data: BaseResponse = response.data;

					if (data.errorCode != ErrorCode.Success) {
						this.setErrorCode(data.errorCode!);
						this.setCurrentUserScreen(UserScreen.P2PTransactionFail);
					}
				});
			} catch (error) {
				router.push("/internal-error");
				this.clearTransactionEventId();
				console.log(error);
			}
		},
		sendConfirm(transactionEventId: string) {
			this.setupIsLoadingFlag(true, "Підтвердження оплати...");

			try {
				HttpRequestDispatcher.SendRequest(
					HttpRequestMethod.GET,
					`/api/P2PConfirmation/ConfirmPayment/${transactionEventId}`
				);
			} catch (error) {
				router.push("/internal-error");
				this.clearTransactionEventId();
				console.log(error);
			}
		},
		async initWebSocket(transactionEventId: string): Promise<void> {
			const connection = new SignalR.HubConnectionBuilder()
				.withUrl(`/bvr-p2p-pay?transactionEventId=${transactionEventId}`)
				.withAutomaticReconnect()
				.build();

			connection.keepAliveIntervalInMilliseconds = 60000;

			connection.on("p2pPaymentConfirmationResult", (errorCode: ErrorCode) => {
				if (errorCode === ErrorCode.Success) {
					this.setCurrentUserScreen(UserScreen.P2PTransactionComplete);
					this.setErrorCode(ErrorCode.Success);
				} else {
					this.setCurrentUserScreen(UserScreen.P2PTransactionFail);
					this.setErrorCode(errorCode);
				}
				this.setupIsLoadingFlag(false);
				this.clearTransactionEventId();
			});
			connection.on("p2pCardPayCriticalError", (message: string) => {
				this.setErrorCode(ErrorCode.Fail);
				this.setupIsLoadingFlag(false);
				this.clearTransactionEventId();
				console.error(message);
				router.push("/internal-error");
			});

			await connection.start();
		},
	},
});
